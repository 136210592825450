// import axios from "axios";

// export const axiosInstance = axios.create();
// let cancelTokenSource;

// axiosInstance.interceptors.request.use(
//   (config) => {

//     if (cancelTokenSource) {
//       console.log('Canceling previous request');
//       cancelTokenSource.cancel('Canceling previous request');
//     }

//     cancelTokenSource = axios.CancelToken.source();
//     config.cancelToken = cancelTokenSource.token;

//     const auth = localStorage.getItem("auth");
//     const country = localStorage.getItem("country");
//     const token = auth ? auth : null;

//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     if (country) {
//       config.headers['country'] = country;
//     }

//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// // TODO: Need to remove the ng-rok warning header in Production

// export const apiConnector = (method, url, bodyData, headers = { "ngrok-skip-browser-warning": "69420" }, params = {}) => {
//   return axiosInstance({
//     method: method,
//     url: url,
//     data: bodyData || null,
//     headers: { ...headers },
//     params: params || null,
//   });
// };


import axios from "axios";

// Create an axios instance
export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    const auth = localStorage.getItem("auth");
    const country = localStorage.getItem("country");
    const token = auth ? auth : null;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (country) {
      config.headers['country'] = country;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

// Track ongoing requests
const ongoingRequests = {};

export const apiConnector = (method, url, bodyData, headers = { "ngrok-skip-browser-warning": "69420" }, params = {}) => {
  const cancelTokenSource = axios.CancelToken.source();

  // Cancel any ongoing request for the same URL and method
  if (ongoingRequests[url]) {
    console.log('Canceling previous request for URL:', url);
    ongoingRequests[url].cancel('Canceling previous request');
  }

  // Store the cancel token source
  ongoingRequests[url] = cancelTokenSource;

  return axiosInstance({
    method: method,
    url: url,
    data: bodyData || null,
    headers: { ...headers },
    params: params || null,
    cancelToken: cancelTokenSource.token,
  })
    .then((response) => {
      // Remove the reference after the request is completed
      delete ongoingRequests[url];
      return response;
    })
    .catch((error) => {
      // Remove the reference on error as well
      delete ongoingRequests[url];
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        return Promise.reject(error);
      }
    });
};
