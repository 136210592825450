import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDrivers } from 'services/operations/DriverApi';
import DataTable from 'react-data-table-component';
import { columns } from './DataTableColumns';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import SoftBox from 'components/SoftBox';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { useNavigate } from 'react-router-dom';
import { LoadingSpinner } from 'examples/LoadingSpinner/LoadingSpinner';

const columnNames = [
  {
    name: 'Profile',
    Value: 'profileImg',
    FieldName: 'IMAGE',
    selector: (row) => row.profileImg,
  },
  {
    name: 'Name',
    Value: 'name',
    FieldName: 'NAME',
    selector: (row) => `${row.name} ${row.last_name}`,
  },
  {
    name: 'Email',
    Value: 'email',
    FieldName: 'NORMAL',
    selector: (row) => row.email,
  },
  {
    name: 'Phone',
    Value: 'phone',
    FieldName: 'NORMAL',
    selector: (row) => row.phone,
  },
  {
    name: 'Action',
    // FieldName: 'BUTTON',
    FieldName: 'STATUS',
  },
];

const Driver = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchFilter = useSelector((state) => state.search.search);

  const [loading, setLoading] = useState(false);
  const [rowStates, setRowStates] = useState({});

  const { Drivers, Loading, Country } = useSelector((state) => ({
    Drivers: state.driver.drivers,
    Loading: state.driver.loading,
    Country: state.country.country
  }));

  const handleRowClick = (row) => {
    navigate(`/profile-details/${row.user_id}`);
  };

  useEffect(() => {
    setLoading(Loading);
  }, [Loading]);

  useEffect(() => {
    dispatch(getDrivers());
  }, [dispatch, Country]);

  const filteredUsers = Array.isArray(Drivers)
    ? Drivers.filter((user) => {
        const name = `${user?.name ?? 'N/A'} ${user?.last_name ?? ''}`.toLowerCase();
        const email = (user?.email ?? '').toLowerCase();
        const phone = user.phone.toString();
        const searchTerm = (searchFilter ?? '').toLowerCase();

        return name.includes(searchTerm) || email.includes(searchTerm) || phone.includes(searchTerm);
      })
    : [];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={5} mb={5}>
        <DataTable
          // title="Driver List"
          data={filteredUsers.length ? filteredUsers : []}
          columns={columns(columnNames, rowStates, setRowStates)}
          highlightOnHover
          pointerOnHover
          progressPending={loading}
          progressComponent={<LoadingSpinner />}
          striped
          onRowClicked={handleRowClick}
          pagination
        />
      </SoftBox>
    </DashboardLayout>
  );
};

export default Driver;
