import moment from "moment";

export const columns = (columnNames) => {

    return columnNames?.map((column) => {
        let col;
        switch (column?.FieldName) {

            case "NORMAL":
                return col = {
                    name: <span className='font-weight-bold fs-13 text-lg'style={{
                        fontSize:'0.9rem'
                    }}>{column?.name}</span>,
                    selector: row => {
                        const value = column.selector(row);
                        return value !== undefined ? value : null;
                    },
                    wrap: true,
                    width:'18rem'
                };
                break;

            case "DURATION":
                return col = {
                    name: <span className='font-weight-bold fs-13 text-lg'style={{
                        fontSize:'0.9rem'
                    }}>{column?.name}</span>,
                    selector: (row) => {
                        const startedAt = row?.started_at;
                        const completedAt = row?.completed_at;

                        if (startedAt && completedAt) {
                            const duration = moment.duration(moment(completedAt).diff(moment(startedAt)));
                            const hours = Math.floor(duration.asHours());
                            const minutes = duration.minutes();
                            return `${hours}h ${minutes}m`;
                        }
                        return '0h 0m'; // or any default value
                    },
                    wrap: true
                };
                break;

            case "DISTANCE":
                return col={
                    name: <span className='font-weight-bold fs-13 text-lg'style={{
                        fontSize:'0.9rem'
                    }}>{column?.name}</span>,
                    selector: (row) => {
                        const distance = row?.ride_request?.distance;

                        return `${distance} Km`; // or any default value
                    },
                    wrap: true
                }
            break;
            default:
                return col = {
                    name: <span className='font-weight-bold fs-13'style={{
                        fontSize:'0.9rem'
                    }}>{column?.name}</span>,
                    selector: row => {
                        return ['CreatedDate', 'ModifiedDate'].includes(column?.Title) ? new Date(row[column?.Title]).toDateString() : row[column?.Title]
                    },
                    wrap: true,
                    width:'8rem'
                };
        }
    })
}