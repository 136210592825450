import moment from "moment";
import React from 'react';

export const columns = (columnNames) => {
    return columnNames?.map((column) => {
        let col;
        switch (column?.fieldName) {
            case "NORMAL":
                col = {
                    name: <span
                        className='font-weight-bold fs-13 text-lg'
                        style={{ fontSize: '0.9rem' }}
                    >
                        {column?.name}
                    </span>,
                    selector: row => {
                        const value = column.selector(row);
                        return value !== undefined ? value : null;
                    },
                    wrap: true,
                    minWidth: '8rem'
                };
                break;
                
            case "DESTINATION":
                col = {
                    name: <span className='font-weight-bold fs-13 text-lg' style={{
                        fontSize: '0.9rem'
                    }}>{column?.name}</span>,
                    selector: row => {
                        const value = column.selector(row);
                        return value !== undefined ? value : null;
                    },
                    wrap: true,
                    width: "22rem"
                };
                break;
            case "DURATION":
                col = {
                    name: <span className='font-weight-bold fs-13 text-lg' style={{
                        fontSize: '0.9rem'
                    }}>{column?.name}</span>,
                    selector: (row) => {
                        const startedAt = row?.started_at;
                        const completedAt = row?.completed_at;

                        if (startedAt && completedAt) {
                            const duration = moment.duration(moment(completedAt).diff(moment(startedAt)));
                            const hours = Math.floor(duration.asHours());
                            const minutes = duration.minutes();
                            return `${hours}h ${minutes}m`;
                        }
                        return '0h 0m';
                    },
                    wrap: true,
                };
                break;
            case "DISTANCE":
                return col = {
                    name: <span className='font-weight-bold fs-13 text-lg' style={{
                        fontSize: '0.9rem'
                    }}>{column?.name}</span>,
                    selector: (row) => {
                        const distance = row?.ride_request?.distance;

                        return `${distance} Km`; // or any default value
                    },
                    wrap: true,

                };

            case "AMOUNT":
                return col = {
                    name: <span className='font-weight-bold fs-13 text-lg' style={{
                        fontSize: '0.9rem'
                    }}>{column?.name}</span>,
                    selector: (row) => {
                        const amount = row?.ride_request?.amount;
                        const currency = row?.ride_request?.currency;

                        return `${amount} ${currency || ''}`; // or any default value
                    },
                    wrap: true
                };
                break;
            default:
                col = {
                    name: <span className='font-weight-bold fs-13' style={{
                        fontSize: '0.9rem'
                    }}>{column?.name}</span>,
                    selector: row => {
                        return ['CreatedDate', 'ModifiedDate'].includes(column?.value)
                            ? new Date(row[column?.value]).toDateString()
                            : row[column?.value]
                    },
                    wrap: true
                };
                break;
        }
        return col;
    });
};
