import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import CircularProgress from '@mui/material/CircularProgress';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Overview page components
import Header from "./components/Header";
import PlatformSettings from "./components/PlatformSettings";

import { useParams } from "react-router-dom";
import { getDriverDetails } from "services/operations/DriverApi";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { columns } from "./DataTableColumns";
import { Box, CardMedia, Dialog, DialogContent, Typography } from "@mui/material";

const columnsNames = [
    {
        name: 'Source',
        Value: 'ride_request.source_name',
        FieldName: 'NORMAL',
        selector: (row) => row?.ride_request?.source_name
    },
    {
        name: 'Destination',
        Value: 'destination_name',
        FieldName: 'NORMAL',
        selector: (row) => row?.ride_request?.destination_name
    },
    {
        name: 'Distance',
        Value: 'distance',
        FieldName: 'DISTANCE',
        selector: (row) => row?.ride_request?.distance
    },
    {
        name: 'Duration',
        FieldName: 'DURATION',
    },
    {
        name: 'Status',
        Value: 'status',
        Title:'status',
        selector: (row) => row?.status
    },
];

const ProfileDetails = () => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { user_id } = useParams();
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const [driverLoading,setDriverLoading]=useState(true);

    const handleClickOpen = (image) => {
        setSelectedImage(image);
        setOpen(true);
    };

    const { Driver, Loading } = useSelector((state) => ({
        Driver: state.driver.driverDetails,
        Loading: state.driver.loading
    }));

    const handleClose = () => {
        setOpen(false);
        setSelectedImage(null);
    };

    useEffect(() => {
        const fetchDriverDetails = async () => {
            await dispatch(getDriverDetails({ user_id }));
            setDriverLoading(false);
        };
    
        fetchDriverDetails();
    }, [dispatch, user_id]);

    useEffect(() => {
        setLoading(Loading)
    }, [Loading]);

    let images = [
        {
            title: "Driving License",
            url: `${Driver?.driver?.driving_license}`
        },
        {
            title: "Identity Card",
            url: `${Driver?.driver?.ghana_card}`
        }
    ];

    return (
        <DashboardLayout>
            {driverLoading?(<Box sx={{ display: 'flex' ,justifyContent:'center'}}>
                <CircularProgress />
                </Box>):
                (<>
                <Header Driver={Driver}selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            {selectedTab===1 &&(
                <SoftBox mt={5} mb={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} xl={4}>
                        <PlatformSettings
                            user_id={user_id}
                            name={Driver?.name}
                            email={Driver?.email}
                            mobile={Driver?.phone}
                            bike={Driver?.bike?.type && Driver?.bike?.plate ? `${Driver.bike.type} (${Driver.bike.plate})` : 'Unknown'}
                            wallet={Driver?.wallet?.amount && Driver?.wallet?.currency ? `${Driver.wallet.amount} ${Driver.wallet.currency}` : 'Unknown'}
                        />
                    </Grid>
                    <Grid item xs={12} xl={4}>
                        <SoftBox paddingBottom={2}>
                        <Card  style={{ marginBottom: '10px' }} onClick={() => handleClickOpen(images[0])}>
                                    <Typography p={2} variant="h6" component="h4">
                                        {images[0]?.title}
                                    </Typography>
                                    <CardMedia
                                        component="img"
                                        height="350"
                                        image={images[0]?.url}
                                        alt={"image"}
                                        paddingBottom={2}
                                        sx={{
                                            paddingBottom:2,
                                            objectFit:'contain'
                                        }}
                                    />
                                </Card>
                        </SoftBox>
                        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                            <DialogContent>
                                {selectedImage && (
                                    <img src={selectedImage.url} alt={"driving license"} style={{ width: '100%' }} />
                                )}
                            </DialogContent>    
                        </Dialog>
                    </Grid>
                    <Grid item xs={12} xl={4}>
                        <SoftBox >
                        <Card  style={{ marginBottom: '10px' }} onClick={() => handleClickOpen(images[1])}>
                                    <Typography p={2} variant="h6" component="h4">
                                        {images[1]?.title}
                                    </Typography>
                                    <CardMedia
                                        component="img"
                                        height="350"
                                        image={images[1]?.url}
                                        alt={"image"}
                                        sx={{
                                            paddingBottom:2,
                                            objectFit:'contain'
                                        }}
                                    />
                                </Card>
                        </SoftBox>
                        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                            <DialogContent>
                                {selectedImage && (
                                    <img src={selectedImage.url} alt={"Ghana Card"} style={{ width: '100%' }} />
                                )}
                            </DialogContent>
                        </Dialog>
                    </Grid>
                </Grid>
            </SoftBox>
            )}
            {selectedTab===0 &&(
                <SoftBox mb={3} mt={2}>
                <Card>
                    <SoftBox pt={2} px={2}>
                        <SoftBox mb={0.5}>
                            <SoftTypography variant="h6" fontWeight="medium">
                                All Rides
                            </SoftTypography>
                        </SoftBox>
                        {/* <SoftBox mb={1}>
                            <SoftTypography variant="button" fontWeight="regular" color="text">
                                Driver Rides
                            </SoftTypography>
                        </SoftBox> */}
                    </SoftBox>
                    <SoftBox p={2}>
                        <DataTable
                            data={Driver?.rides}
                            columns={columns(columnsNames)}
                            highlightOnHover
                            progressPending={loading}
                            striped
                            pagination
                        />
                    </SoftBox>
                </Card>
            </SoftBox>
            )}</>)}
        </DashboardLayout>
    );
}

export default ProfileDetails